<template>
  <div>
    <!-- start of item dialog -->
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          small
          dark
          @click="openDialog"
        >
          <v-icon left>mdi-plus</v-icon>New Item
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Item Entry
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="Item No."
                v-model="selectedItem.ItemCode"
                color="primary"
                :append-icon="'mdi-airballoon'"
                @click:append="openItemModal"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Item Description"
                v-model="selectedItem.ItemName"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Planned Quantity"
                v-model="itemRecord.PlanQty"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Price/ Sqrft"
                v-model="itemRecord.U_PricePerPricingUnit"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Sqrft"
                v-model="itemRecord.U_Sqrft"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Unit/ Month Price"
                v-model="itemRecord.UnitPrice"
              ></v-text-field>
            </v-col>
            <!-- end -->
            <v-col cols="3">
              <v-text-field
                label="Cummilative Committed quantity"
                v-model="record.i"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Cummulative Commited Amount(LC)"
                v-model="record.i"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <!-- end -->
            <v-col cols="3">
              <v-text-field
                label="Cummulative Quantity"
                v-model="record.i"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Cummulative Amount(LC)"
                v-model="record.i"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Open Quantity"
                v-model="itemRecord.PlanQty"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Open Amount(LC)"
                v-model="record.i"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <!-- end -->
            <v-col cols="3">
              <v-text-field
                label="Item Group"
                v-if="selectedItem.oitb"
                v-model="selectedItem.oitb.ItmsGrpNam"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Free Text"
                v-model="itemRecord.FreeTxt"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="UOM Code"
                v-model="selectedItem.SalUnitMsr"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Inventory UOM Name"
                v-model="selectedItem.InvntryUom"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Items Per Unit"
                v-model="itemRecord.ItmsPerMsr"
                filled
                readonly
              ></v-text-field>
            </v-col>

            <!-- end -->

            <v-col cols="3">
              <v-text-field
                label="UoM Group"
                v-if="selectedItem.ougp"
                v-model="selectedItem.ougp.UgpCode"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Portion Of Return %"
                v-model="itemRecord.RetPortion"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Item Row Status"
                v-model="LineStatus"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <!-- end -->
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="accent"
                @click="addItem"
              >
                <v-icon left>mdi-plus</v-icon>Add Item
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item dialog -->

    <!-- one item modal -->
    <v-dialog
      v-model="itemDialog"
      width="950"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="itemDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :search="searchItem"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </div>
</template>

<script>
export default {
  props: {
    selectedTenant: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      itemDialog: false,
      selectedItems: [],
      itemMasterData: [],
      selectedItem: {},
      itemRecord: {},
      record: {},
      LineStatus: "open",
      searchItem: null,
      itemsPerPage: 20,
      singleSelect: true,
      itemMasterHeaders: [
        { text: "Item Code", value: "ItemCode" },
        { text: "Item Description", value: "ItemName" },
        { text: "UOM", value: "SalUnitMsr" },
        { text: "Sales Tax group", value: "VatGourpSa" },
      ],
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    openItemModal() {
      this.selectedItems = [];
      this.itemDialog = true;
    },
    addItem() {
      const sItem = this.selectedItem;
      const itemRecord = this.itemRecord;
      const LineStatus = this.LineStatus;
      const details = {
        ItemCode: sItem.id,
        ItemName: sItem.ItemName,
        PlanQty: itemRecord.PlanQty,
        U_PricePerPricingUnit: itemRecord.U_PricePerPricingUnit,
        U_Sqrft: itemRecord.U_Sqrft,
        UnitPrice: itemRecord.UnitPrice,
        InvntryUom: sItem.InvntryUom,
        SUoMEntry: sItem.SalUnitMsr,
        UomCode: sItem.SalUnitMsr,
        FreeTxt: itemRecord.FreeTxt,
        ItemGroup: sItem.oitb.ItmsGrpNam,
        UomEntry: sItem.SUoMEntry,
        UomGroup: sItem.ougp.UgpCode,
        ItmsPerMsr: itemRecord.ItmsPerMsr,
        RetPortion: itemRecord.RetPortion,
        LineStatus: LineStatus,
      };
      this.$emit("itemData", details);
      this.selectedItem = {};
      this.itemRecord = {};
      this.dialog = false;
    },
    clickedItem($event) {
      if (this.selectedTenant.id) {
        this.selectedItem = $event.item;
        // get autopopulated
        const data = {
          ItemCode: this.selectedItem.id,
          CardCode: this.selectedTenant.id,
        };
        const self = this;
        // make prices query request
        const url = "/getDefaultPrice";
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            self.itemRecord.U_PricePerPricingUnit = res.PRICEPERPRICEUNIT;
            self.itemRecord.U_Sqrft = res.SalesUnitPriceUnitConversion;
            self.itemRecord.ItmsPerMsr = res.SalesUnitINVUnitConversion;
            self.itemRecord.UnitPrice = res.FINALSALESPRICE;
            self.itemRecord.Currency = res.PRICINGCURRENCY;
            // close dialog
            self.itemDialog = false;
          })
          .catch((err) => {
            console.log(err, "err");
          });
        // end of request
        // end of get
      } else {
        this.$refs.snackbar.show("Please Select Customer!", "red");
        this.dialog = false;
        this.itemDialog = false;
      }
    },
    getItemMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/item_masterdata`)
        .then((res) => {
          self.itemMasterData = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getItemMaster();
  },
};
</script>

<style  scoped>
</style>