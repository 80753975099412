<template>
  <v-form>
    <v-row>
      <v-col cols="4">
        <v-text-field
          label="Tenant Code"
          v-model="selectedTenant.CardCode"
          :append-icon="'mdi-airballoon'"
          @click:append="openBp"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Tenant Name"
          v-model="selectedTenant.CardName"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Tenant RefNo."
          v-model="record.NumAtCard"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          v-model="record.Method"
          :items="agreementMethods"
          item-text="name"
          item-value="value"
          label="Agreement Method"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setSignDate"
          :myDate="date"
          :title="`Signing Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setStartDate"
          :myDate="date"
          :title="`Lease Start Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Lease Months"
          v-model="record.U_Months"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Lease End Date"
          v-model="U_LeaseEndDate"
          @click="setEndDate"
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Extension Months"
          v-model="record.U_ExtensionMonths"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Extension End Date"
          v-model="ExtensionEndDate"
          @click="setExtensionEndDate"
          readonly
        ></v-text-field>
      </v-col>

      <!-- tabs start here -->
      <v-tabs
        background-color="accent"
        color="white"
      >
        <v-tab>General</v-tab>
        <v-tab>Details</v-tab>
        <v-tab>Documents</v-tab>
        <v-tab>Attachments</v-tab>
        <v-tab>Recurring Transactions</v-tab>
        <!-- v-tab-items -->

        <!-- general -->
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="6">
              <v-row>
                <v-col cols="7">
                  <v-autocomplete
                    dense
                    v-model="record.Type"
                    :items="agreementTypes"
                    item-text="name"
                    item-value="value"
                    label="Agreement Type"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="7">
                  <v-text-field
                    label="Settlement Probability %"
                    v-model="record.SettleProb"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="7">
                  <v-autocomplete
                    dense
                    v-model="record.Status"
                    :items="status"
                    item-text="name"
                    item-value="value"
                    label="Status"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="7">
                  <v-text-field
                    label="Owner"
                    v-model="record.Owner"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                v-model="record.Remarks"
                label="Remarks"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of general -->

        <!-- start of details tab-->
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <!-- new item activator  -->
              <new-item-modal
                :selectedTenant="selectedTenant"
                @itemData="addItemData"
              ></new-item-modal>
              <!-- end -->
            </v-col>
            <v-col
              cols="12"
              style="overflow-x: auto; display: flex; min-width: 100%"
            >
              <!-- start of items table -->
              <items-table :saleItemsData="saleItemsData"></items-table>
              <!-- end of items table -->
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of details tab-->

        <!-- start of documents -->
        <v-tab-item>
          <v-row
            dense
            class="pa-3"
          >
            <v-col cols="4">
              <v-row>Documents come here</v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of documents -->

        <!-- start of attachments -->
        <v-tab-item>
          <v-row
            dense
            class="pa-3"
          >Attachments</v-row>
        </v-tab-item>
        <!-- end of attachments -->

        <!-- start of attachments -->
        <v-tab-item>
          <v-row
            dense
            class="pa-3"
          >
            <v-col cols="12">
              <v-btn
                color="primary"
                small
                @click.native="openTemplateModal"
              >
                <v-icon>mdi-plus</v-icon>New Transaction
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="templatesHeaders"
                :items="recurrentTransactions"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- end of attachments -->
      </v-tabs>

      <v-col cols="12">
        <v-btn
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- start of bp fullscreen modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="bpDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Select Customer
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="bpDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clicked"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :search="search"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end -->

    <!-- start of recurrent transactions templates modal -->
    <v-dialog
      v-model="templateDialog"
      width="1300px"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>List of Recurrent Transactions Templates</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="templateDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchTemplate"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedTemplate"
                  :items-per-page="itemsPerPage"
                  v-model="selectedTemplates"
                  :headers="templatesHeaders"
                  :items="templatesData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :search="searchTemplate"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of recurrent transaction templates modal -->

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end -->
  </v-form>
</template>


<script>
import NewItemModal from "./item-dialog.vue";
import ItemsTable from "./items-table.vue";
export default {
  components: {
    NewItemModal,
    ItemsTable,
  },
  props: {
    initial: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Array,
    },
    templatesData: {
      type: Array,
    },
  },
  data: () => ({
    record: {},
    search: null,
    selected: [],
    selectedTenant: {},
    itemsPerPage: 20,
    singleSelect: true,
    dialog: false,
    loading: false,
    bpDialog: false,
    itemDialog: false,
    trueValue: true,
    templateDialog: false,
    searchTemplate: null,
    selectedTemplates: [],
    tab: null,
    date: null,
    SignDate: null,
    StartDate: null,
    EndDate: null,
    ExtensionEndDate: null,
    U_LeaseEndDate: null,
    priceList: [],
    salesEmployees: [],
    bpMasterData: [],
    saleItemsData: [],
    recurrentTransactions: [],
    recurrentData: [],
    agreementTypes: [
      { name: "General", value: "G" },
      { name: "Specific", value: "S" },
    ],
    agreementMethods: [
      { name: "Items Method", value: "I" },
      { name: "Monetary Method", value: "M" },
    ],
    status: [
      { name: "Approved", value: "A" },
      { name: "Draft", value: "D" },
    ],
    itemGroups: [],
    series: [{ name: "Manual", value: "M" }],
    itemTypes: [
      { name: "Fixed Assets", value: "F" },
      { name: "Items", value: "I" },
      { name: "Labour", value: "L" },
      { name: "Travel", value: "T" },
      { name: "Building", value: "B" },
    ],
    bpHeaders: [
      { text: "Code", value: "CardCode" },
      { text: "Name", value: "CardName" },
      { text: "Foreign Name", value: "CardFName" },
      { text: "Group", value: "GroupCode" },
    ],
    templatesHeaders: [
      { text: "Template", value: "Code" },
      { text: "Description", value: "Dscription" },
      { text: "Type", value: "DocObjType" },
      { text: "Doc No.", value: "DraftEntry" },
      { text: "Recurrence Date", value: "Remind" },
      { text: "Recurrence Period", value: "Frequency" },
      { text: "Start Date", value: "StartDate" },
      { text: "Next Execution", value: "" },
      { text: "Valid Until", value: "EndDate" },
    ],
    groups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    addItemData(data) {
      this.saleItemsData.push(data);
    },
    openTemplateModal() {
      this.selectedTemplates = [];
      this.templateDialog = true;
    },
    clicked($event) {
      this.selectedTenant = $event.item;
      this.bpDialog = false;
    },
    clickedTemplate($event) {
      const data = { RcpEntry: $event.item.id };
      this.recurrentData.push(data);
      this.recurrentTransactions.push($event.item);
      this.templateDialog = false;
    },
    setSignDate(date) {
      this.SignDate = date;
    },
    setStartDate(date) {
      this.StartDate = date;
      this.record.U_LeaseStartDate = date;
    },
    daysInMonth(year, month) {
      return new Date(year, month + 1, 0).getDate();
    },
    addMonths(date, months) {
      var target_month = date.getMonth() + months;
      var year = date.getFullYear() + parseInt(target_month / 12);
      var month = target_month % 12;
      var day = date.getDate();
      var last_day = this.daysInMonth(year, month);
      if (day > last_day) {
        day = last_day;
      }
      var new_date = new Date(year, month, day);
      return new_date;
    },
    setEndDate() {
      const months = this.record.U_Months;
      if (this.StartDate && months) {
        var StartDate = new Date(this.StartDate);
        const endDate = this.addMonths(StartDate, parseInt(months));
        // display date
        const date = new Date(endDate);
        const eDate =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();

        const savingEndDate =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        // update record
        this.record.U_LeaseEndDate = savingEndDate;
        this.U_LeaseEndDate = eDate;
      } else {
        this.U_LeaseEndDate = null;
      }
    },
    setExtensionEndDate() {
      const months = this.record.U_ExtensionMonths;
      if (this.record.U_LeaseEndDate && months) {
        var EndDate = new Date(this.record.U_LeaseEndDate);
        this.record.EndDate = EndDate;
        const eEndDate = this.addMonths(EndDate, parseInt(months));
        // display date
        const date = new Date(eEndDate);
        const eDate =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
        this.ExtensionEndDate = eDate;
        // update record
        const savingExtEndDate =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        this.record.EndDate = savingExtEndDate;
      } else {
        this.ExtensionEndDate = null;
      }
    },
    sendData() {
      this.record.BpCode = this.selectedTenant.id;
      this.record.BpName = this.selectedTenant.CardName;
      this.record.oat1 = this.saleItemsData;
      this.record.RecurrentTransactions = this.recurrentData;
      this.$emit("data", this.record);
    },
    openBp() {
      this.bpDialog = true;
    },
    getItemGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getItemGroups();
    this.getBpMaster();
  },
};
</script>

