export const itemHeaders = [
    { text: "Item No.", value: "id" },
    { text: "Item Description", value: "country.name" },
    { text: "Planned Quantity", value: "name" },
    { text: "Price/ Sqrft", value: "" },
    { text: "Square Feet", value: "" },
    { text: "Unit / Month Price", value: "" },
    { text: "Cummulative Commited Qty.", value: "" },
    { text: "Cummulative Committed Amount(LC)", value: "" },
    { text: "Cummulative Quantity", value: "" },
    { text: "Open Quantity", value: "" },
    { text: "Open Amount", value: "" },
    { text: "Item Group", value: "" },
    { text: "Free Text", value: "" },
    { text: "UOM Code", value: "" },
    { text: "Inventory UoM Name", value: "" },
    { text: "Items Per Unit", value: "" },
    { text: "UOM Group", value: "" },
    { text: "Portion of Return %", value: "" },
    { text: "Item Row Status", value: "" },
];