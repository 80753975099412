<template>
  <v-row>
    <v-col cols="12" style="overflow-x: auto; display: flex; min-width: 100%">
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                style="min-width: 250px"
                class="text-left"
                v-for="header in itemHeaders"
                v-bind:key="header.text"
              >{{header.text}}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in saleItemsData" :key="index">
              <td>{{ item.ItemCode }}</td>
              <td>{{ item.ItemName }}</td>
              <td>{{ item.PlanQty }}</td>
              <td>{{ item.U_PricePerPricingUnit }}</td>
              <td>{{ item.U_Sqrft }}</td>
              <td>{{item.UnitPrice}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ item.PlanQty }}</td>
              <td>open amount</td>
              <td>{{item.ItemCode}}</td>
              <td>{{ item.FreeTxt }}</td>
              <td>{{ item.SUoMEntry }}</td>
              <td>{{ item.InvntryUom }}</td>
              <td>{{item.ItmsPerMsr}}</td>
              <td>{{ item.UomGroup}}</td>
              <td>{{item.RetPortion}}</td>
              <td>{{item.LineStatus}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- end of data table -->
    </v-col>
  </v-row>
</template>

<script>
import { itemHeaders } from "../_data/index.js";
export default {
  props: {
    saleItemsData: {
      type: Array
    }
  },
  data() {
    return {
      itemHeaders: itemHeaders
    };
  }
};
</script>

<style scoped>
</style>